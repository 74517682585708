var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthPageTemplate',{attrs:{"button":_vm.$t('auth.login.button.login'),"loading":_vm.loadingLogin || _vm.isTimeout,"disabled":!(
      _vm.username != null &&
      _vm.isMail == true &&
      _vm.password != null &&
      _vm.password.length > 5
    ),"error":_vm.errorHandle},on:{"click":_vm.loginUser},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"black--text mb-7",staticStyle:{"font-size":"15px"}},[_c('div',{staticClass:"mb-1 black--text",staticStyle:{"font-size":"20px"}},[(_vm.isReload.status == true && _vm.isReload.type == 'register')?_c('b',[_vm._v(_vm._s(_vm.$t('auth.login.title.register')))]):(_vm.isReload.status == true && _vm.isReload.type == 'reload')?_c('b',[_vm._v(_vm._s(_vm.$t('auth.login.title.reload')))]):(_vm.isReload.status == true && _vm.isReload.type == 'reset')?_c('b',[_c('v-icon',{staticClass:"mr-2",staticStyle:{"margin-bottom":"2px"},attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]),_vm._v(_vm._s(_vm.$t('auth.login.title.reset')))],1):_c('b',[_vm._v(_vm._s(_vm.$t('auth.login.title.default')))])]),(_vm.isReload.status == true)?_c('div',{staticClass:"font-weight-normal grey--text"},[(_vm.isReload.type == 'reload')?_c('div',{domProps:{"textContent":_vm._s(_vm.$t('auth.login.text.reload'))}}):(_vm.isReload.type == 'register')?_c('div',{domProps:{"textContent":_vm._s(_vm.$t('auth.login.text.register'))}}):(_vm.isReload.type == 'reset')?_c('div',{domProps:{"textContent":_vm._s(_vm.$t('auth.login.text.reset'))}}):_c('div',{domProps:{"textContent":_vm._s(_vm.$t('auth.login.text.unknown'))}})]):_c('div',{staticClass:"font-weight-normal grey--text",domProps:{"textContent":_vm._s(_vm.$t('auth.login.text.default'))}})]),_c('TextInput',{staticClass:"mt-1",attrs:{"disabled":_vm.isTimeout || _vm.loadingLogin,"placeholder":_vm.$t('auth.login.input.mail'),"value":_vm.username},on:{"change":_vm.updateUsernameEvent}}),_c('TextInput',{staticClass:"mt-3",attrs:{"disabled":_vm.isTimeout || _vm.loadingLogin,"placeholder":_vm.isReload.type == 'register'
          ? _vm.$t('auth.login.input.temporaryPassword')
          : _vm.$t('auth.login.input.password'),"password":true,"value":_vm.password},on:{"change":_vm.updatePasswordEvent}}),_c('v-row',{staticClass:"ma-0 mt-2 mb-1",attrs:{"align":"center"}},[_c('v-spacer'),_c('TextButton',{style:(_vm.isTimeout || _vm.loadingLogin
            ? 'opacity: .2; pointer-events: none;'
            : ''),attrs:{"buttonColor":"white","dense":"","text":_vm.$t('auth.login.button.forgotPassword')},on:{"click":function($event){_vm.$router
            .push({name: 'restore', query: {mail: _vm.username}})
            .catch(function () {})}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }