<template>
  <AuthPageTemplate
    :button="$t('auth.login.button.login')"
    :loading="loadingLogin || isTimeout"
    :disabled="
      !(
        username != null &&
        isMail == true &&
        password != null &&
        password.length > 5
      )
    "
    :error="errorHandle"
    v-on:click="loginUser"
  >
    <template v-slot:input>
      <div class="black--text mb-7" style="font-size: 15px">
        <div style="font-size: 20px" class="mb-1 black--text">
          <b v-if="isReload.status == true && isReload.type == 'register'">{{
            $t('auth.login.title.register')
          }}</b>
          <b v-else-if="isReload.status == true && isReload.type == 'reload'">{{
            $t('auth.login.title.reload')
          }}</b>
          <b v-else-if="isReload.status == true && isReload.type == 'reset'"
            ><v-icon color="success" style="margin-bottom: 2px" class="mr-2"
              >mdi-check-circle</v-icon
            >{{ $t('auth.login.title.reset') }}</b
          >
          <b v-else>{{ $t('auth.login.title.default') }}</b>
        </div>
        <div
          class="font-weight-normal grey--text"
          v-if="isReload.status == true"
        >
          <div
            v-if="isReload.type == 'reload'"
            v-text="$t('auth.login.text.reload')"
          />
          <div
            v-else-if="isReload.type == 'register'"
            v-text="$t('auth.login.text.register')"
          />
          <div
            v-else-if="isReload.type == 'reset'"
            v-text="$t('auth.login.text.reset')"
          />
          <div v-else v-text="$t('auth.login.text.unknown')" />
        </div>
        <div
          v-else
          class="font-weight-normal grey--text"
          v-text="$t('auth.login.text.default')"
        />
      </div>

      <TextInput
        :disabled="isTimeout || loadingLogin"
        class="mt-1"
        v-on:change="updateUsernameEvent"
        :placeholder="$t('auth.login.input.mail')"
        :value="username"
      />

      <TextInput
        :disabled="isTimeout || loadingLogin"
        v-on:change="updatePasswordEvent"
        class="mt-3"
        :placeholder="
          isReload.type == 'register'
            ? $t('auth.login.input.temporaryPassword')
            : $t('auth.login.input.password')
        "
        :password="true"
        :value="password"
      />

      <v-row class="ma-0 mt-2 mb-1" align="center">
        <v-spacer />
        <TextButton
          style=""
          v-on:click="
            $router
              .push({name: 'restore', query: {mail: username}})
              .catch(() => {})
          "
          :style="
            isTimeout || loadingLogin
              ? 'opacity: .2; pointer-events: none;'
              : ''
          "
          buttonColor="white"
          dense
          :text="$t('auth.login.button.forgotPassword')"
        />
      </v-row>
    </template>
  </AuthPageTemplate>
</template>

<script>
import AuthPageTemplate from '@/components/auth/AuthPageTemplate.vue';

import TextInput from '@components/text/TextInput.vue';
import TextButton from '@components/button/TextButton.vue';

export default {
  components: {
    AuthPageTemplate,
    TextButton,
    TextInput,
  },

  data() {
    return {
      isTimeout: false,

      isReload: {
        status: false,
        type: 'unknown',
      },

      inviteId: null,
      loadingLogin: false,
      errorHandle: {
        status: false,
        text: '',
      },

      username: '',
      password: '',

      formButton: null,
      disabled: false,
    };
  },

  beforeCreate() {
    document.title = this.$route.meta.tab || this.$product;
  },

  created() {
    if (this.$route.query.type == 'reload') {
      this.isReload.status = true;
      this.isReload.type = 'reload';
    } else if (this.$route.query.type == 'reset') {
      this.isReload.status = true;
      this.isReload.type = 'reset';
      this.$router.replace({});
    } else if (Object.keys(this.$route.query).includes('type')) {
      this.isReload.status = true;
      this.isReload.type = 'unknown';
    }

    if (
      this.$route.query.inviteId != null &&
      this.$route.query.inviteId.length > 0
    ) {
      this.inviteId = this.$route.query.inviteId;
    }
  },

  updated() {
    const submitButton = document.querySelector('#recaptcha-container .v-btn');
    if (!submitButton.disabled) {
      document.addEventListener('keyup', this.enterLogin);
    } else {
      document.removeEventListener('keyup', this.enterLogin);
    }
  },

  computed: {
    isMail() {
      return this.$helper.isValidMail(this.username);
    },
  },

  methods: {
    enterLogin(e) {
      if (e.keyCode === 13) {
        const submitButton = document.querySelector(
          '#recaptcha-container .v-btn',
        );
        submitButton.focus();
        this.loginUser();
      }
    },
    updateUsernameEvent(usernameString) {
      this.username = usernameString;
      this.errorHandle.status = false;
    },
    updatePasswordEvent(passwordString) {
      this.password = passwordString;
      this.errorHandle.status = false;
    },

    async loginUser() {
      this.loadingLogin = true;
      this.errorHandle.status = false;

      const authResp = await this.$auth.login(this.username, this.password);

      // Failed to login
      if (!authResp || !authResp.success || !authResp.user) {
        switch (authResp.message) {
          case 'auth/too-many-requests':
            this.errorHandle.text = this.$t('auth.login.error.timeout');
            this.isTimeout = true;

            setTimeout(() => {
              this.isTimeout = false;
              this.errorHandle.status = false;
            }, 60000);
            break;

          case 'unknown-error':
            this.errorHandle.text = this.$t('auth.login.error.timeout');
            break;

          default:
            this.errorHandle.text = this.$t('auth.login.error.invalid');
            break;
        }

        this.errorHandle.status = true;
        this.password = String();
      } else {
        // var res = await this.$api.user.user()

        // redirect to previous page
        if (
          this.$route.query.route != null &&
          this.$route.query.route.length > 0 &&
          !this.$route.query.route.includes('login')
        ) {
          return this.$router
            .push({path: this.$route.query.route})
            .catch(() => true);
        }

        return this.$router.push(this.$auth.landingPage());
      }

      this.loadingLogin = false;
    },
  },
};
</script>
